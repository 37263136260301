// eslint-disable-next-line import/no-unassigned-import
import "react-app-polyfill/ie11";
// eslint-disable-next-line import/no-unassigned-import
import "react-app-polyfill/stable";

import { createRoot } from "react-dom/client";

import App from "./App";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
